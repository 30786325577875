import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const CardsComponent = ({
  title,
  address,
  phone,
  tradingHours,
  mapUrl,
}: any) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      elevation={0}
      sx={{
        p: '15px',
        borderRadius: '10px',
        width: { xs: '100%', lg: '447px' },
        height: '100%',
        border: '2px solid #f3f4f6',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        mt: 2,
      }}
    >
      <CardContent
        sx={{
          maxHeight: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#101828',
            fontSize: '20px',
            lineHeight: '60px',
            letterSpacing: '-0.48px',
            fontWeight: '700',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              color: '#475467',
              fontWeight: '700',
            }}
          >
            Physical Address
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: '14px',
              color: '#475467',
              fontWeight: '400',
              m: '10px 0',
            }}
          >
            {address}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              color: '#475467',
              fontWeight: '700',
            }}
          >
            Telephone
          </Typography>

          <Typography
            variant="caption"
            sx={{
              fontSize: '14px',
              color: '#475467',
              fontWeight: '400',
              m: '10px 0',
            }}
          >
            {phone}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              color: '#475467',
              fontWeight: '700',
            }}
          >
            Trading Hours
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '10px',
            pb: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              color: '#475467',
              fontWeight: '400',
              m: '10px 0',
            }}
          >
            {tradingHours.map((hours: any, index: any) => (
              <span key={index}>
                {hours.day}: {hours.time}
                <br />
              </span>
            ))}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          onClick={handleOpen}
          fullWidth
          sx={{ borderRadius: 0, py: '20px' }}
          variant="outlined"
        >
          view map
        </Button>
        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            arial-describedby="modal-modal-description"
          >
            <Box
              sx={{
                bgcolor: '#fff',
                position: 'absolute',
                float: 'left',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <iframe
                title={title}
                src={mapUrl}
                width="630"
                height="573"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              ></iframe>
            </Box>
          </Modal>
        </Box>
      </CardActions>
    </Card>
  );
};
export default CardsComponent;
