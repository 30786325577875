import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';

import { useEffect } from 'react';

function BrandsPage() {
  const data = {
    textHeaderData: {
      heading: 'Internationals',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, Maxime, accusamus eum cum voluptatum alias dolorem officiis eligendi anothe accusa eum cum voluptatum alias vitae dolorem officiis eligendi consectetur adipisicing accusamus eum cum laboris nisi ut',
      subContent:
        'Ut enim ipsum dolor sit amet consectetur adipisicing elit. accusamus eum cum.',
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageLayout>
      <TextHeader
        heading={data.textHeaderData.heading}
        content={data.textHeaderData.content}
        subContent={data.textHeaderData.subContent}
        isCentered={false}
        width="70%"
        marginTop={10}
        marginBottom={5}
      />
    </PageLayout>
  );
}

export default BrandsPage;
