import { cartBaseURL } from './baseUrl';

export const getCartByUserId = async (
    authToken: string,
    status_check?: object
) => {
    const response = await cartBaseURL.post(
        '/cart/get-cart-by-user-id',
        status_check,
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        }
    );
    localStorage.setItem('customerId', response.data.customer_id);
    return response.data;
};

export const initiateCartApi = async (authToken: string) => {
    const response = await cartBaseURL.post(
        '/cart',
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        }
    );
    localStorage.setItem('customerId', response.data.customer_id);
    return response.data;
};

export const updateCartById = async (
    authToken: string,
    cartData: {
        completed_at?: string;
        context?: object;
        created_at?: string;
        customer_id?: string;
        deleted_at?: string;
        discount_total?: number;
        id: string;
        item_tax_total?: number;
        items: Array<{}>;
        payment_authorized_at?: string;
        payment_id?: string;
        payment_session_id?: string;
        shipping_address_id?: string;
        shipping_tax_total?: number;
        shipping_total?: number;
        type?: string;
        updated_at?: string;
        customer_order_num?: string;
    }
) => {
    const response = await cartBaseURL.put(
        `/cart`,
        {
            cartData: cartData,
        },
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};

export const finalizeCart = async (cartId: string, authToken: string) => {
    const response = await cartBaseURL.get(
        `/cart/finalize/${cartId}`,

        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};

export const updateCartStatus = async (
    cartId: string,
    authToken: string,
    statusData: object
) => {
    const response = await cartBaseURL.put(
        `cart/update-cart-status/${cartId}`,
        statusData,
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        }
    );

    return response.data;
};

export const getAddressByAuth = async (authToken: string) => {
    const response = await cartBaseURL.get('/cart/get-address-by-auth', {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return response.data;
};

export const getAccountDetails = async (authToken: string) => {
    const response = await cartBaseURL.get('/cart/get-account-id', {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    return response.data;
};

export const pay = async (
    name_first?: string,
    name_last?: string,
    email_address?: string,
    m_payment_id?: string,
    amount?: string,
    item_name?: string,
    get_type?: string
) => {
    const response = await cartBaseURL.post(
        `/payments/pay`,
        {
            name_first: name_first,
            name_last: name_last,
            email_address: email_address,
            m_payment_id: m_payment_id,
            amount: amount,
            item_name: item_name,
            get_type: 'htmlForm',
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const htmlContent = await response.data;
    const paymentContainer = document.getElementById('payment-container');
    if (paymentContainer) {
        paymentContainer.innerHTML = htmlContent;
    } else {
        console.error('payment-container error');
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const merchantIdValue = doc.querySelector(
        'input[name="merchant_id"]'
    ) as HTMLInputElement | null;

    if (merchantIdValue) {
        localStorage.setItem('merchantId', merchantIdValue.value);
    } else {
        console.error('Merchant ID input not found');
    }

    return response.data;
};

export const paymentType = async (authToken: string) => {
    const response = await cartBaseURL.get('/cart/get-payment-type', {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });
    localStorage.setItem('paymentType', response.data);

    return response.data;
};

export const discount = async (authToken: string, stockLink: number) => {
    const response = await cartBaseURL.get(
        `/cart/get-discount-item/${stockLink}`,
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
        }
    );
    return response.data;
};

export const userCredit = async (authToken: string) => {
    const response = await cartBaseURL.get('/credit/get-user-credit', {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    return response.data;
};

export const paymentStatus = async (authToken: string, cartId: string) => {
    const response = await cartBaseURL.get(`/cart/get-cart-status/${cartId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
    });

    return response.data;
};
