import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const Filter = ({
    filterType,
    handleCheckboxChange,
    list,
    setList,
    keyName,
}: any) => {
    return (
        <>
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: '20.5rem',
                }}
            >
                <FormGroup>
                    {filterType
                        ?.sort((a: any, b: any) =>
                            a[keyName].localeCompare(b[keyName])
                        )
                        .map((elem: any, index: number) => (
                            <FormGroup key={index}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={elem?.[keyName]}
                                    onChange={handleCheckboxChange(
                                        elem?.idx,
                                        setList,
                                        list
                                    )}
                                    checked={
                                        list.length === 0
                                            ? false
                                            : list.includes(elem?.idx)
                                    }
                                />
                            </FormGroup>
                        ))}
                </FormGroup>
            </Box>
        </>
    );
};

export default Filter;
