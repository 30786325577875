import {
    getCartByUserId,
    initiateCartApi,
    updateCartById,
    finalizeCart,
    pay,
    getAddressByAuth,
    paymentType,
    userCredit,
    paymentStatus,
    getAccountDetails,
} from '../api/apiCart';

class CartService {
    authToken: string;
    customerId: string;
    paymentType: string = '';

    constructor(authToken: string, customerId: string) {
        this.authToken = authToken;
        this.customerId = customerId;
    }

    async checkAndRetrieveCart() {
        try {
            const cart = await this.getCartByUser();

            if (cart && cart.id) {
                return cart;
            } else {
                const newCart = await this.initiateCart();

                return newCart;
            }
        } catch (error) {
            console.error('Error retrieving or initiating cart:', error);
            throw error;
        }
    }

    async initiateCart() {
        return await initiateCartApi(this.authToken);
    }

    async getCartByUser() {
        return await getCartByUserId(this.authToken);
    }

    async addItemToCart(
        cartId: string,
        cartItemsList: object[]
    ): Promise<object> {
        try {
            const existingCart = await getCartByUserId(this.authToken);
            if (!existingCart || existingCart.id !== cartId) {
                throw new Error('Cart not found or cartId mismatch');
            }
            const existingItems = Array.isArray(existingCart.items)
                ? existingCart.items
                : [];

            const updatedCartData = {
                ...existingCart,
                items: [...existingItems, ...cartItemsList],
            };

            const updatedCart = await updateCartById(
                this.authToken,
                updatedCartData
            );
            return updatedCart;
        } catch (error) {
            console.error('Error adding item to cart:', error);
            throw error;
        }
    }

    async updateCartItems(
        cartId: string,
        updatedCartItemsList: object[]
    ): Promise<object> {
        try {
            const existingCart = await getCartByUserId(this.authToken);
            if (!existingCart || existingCart.id !== cartId) {
                throw new Error('Cart not found');
            }
            const subtotal = updatedCartItemsList.reduce(
                (acc: number, item: any) => {
                    return acc + item.price;
                },
                0
            );

            const vat = 0.15;

            const discountTotal = (subtotal * vat) / (1 + vat);
            const total = subtotal;

            const updatedCartData = {
                ...existingCart,
                items: updatedCartItemsList,
                subtotal: (subtotal - discountTotal).toFixed(2),
                discount_total: discountTotal.toFixed(2),
                total: total.toFixed(2),
                payment_id: cartId,
            };
            const updatedCart = await updateCartById(
                this.authToken,
                updatedCartData
            );
            return updatedCart;
        } catch (error) {
            console.error('Error updating cart items:', error);
            throw error;
        }
    }

    async removeItemFromCart(cartId: string, itemId: string) {
        try {
            const existingCart = await this.getCartByUser();
            if (!existingCart || existingCart.id !== cartId) {
                throw new Error('Cart not found or cartId mismatch');
            }

            const updatedItems = existingCart.items.filter(
                (item: any) => item.id !== itemId
            );

            const subtotal = updatedItems.reduce(
                (acc: number, item: any) => acc + item.price,
                0
            );
            const vat = 0.15;

            const discountTotal = (subtotal * vat) / (1 + vat);
            const total = subtotal;

            const updatedCartData = {
                ...existingCart,
                items: updatedItems,
                subtotal: (subtotal - discountTotal).toFixed(2),
                discount_total: discountTotal.toFixed(2),
                total: total.toFixed(2),
            };
            const updatedCart = await updateCartById(
                this.authToken,
                updatedCartData
            );
            return updatedCart;
        } catch (error) {
            console.error('Error removing item from cart:', error);
            throw error;
        }
    }

    async finalizeUserCart(cartId: string) {
        return await finalizeCart(cartId, this.authToken);
    }

    async clearCartItems(cartId: string): Promise<object> {
        try {
            const emptyCartData = {
                id: cartId,
                items: [],
                total: 0,
                discountedTotal: 0,
                subtotal: 0,
                type: '',
            };
            const clearedCart = await updateCartById(
                this.authToken,
                emptyCartData
            );
            return clearedCart;
        } catch (error) {
            console.error('Error clearing cart items:', error);
            throw error;
        }
    }

    async updateOrderNotes(cartId: string, itemId: string, orderNotes: string) {
        try {
            const existingCart = await this.getCartByUser();
            if (!existingCart || existingCart.id !== cartId) {
                throw new Error('Cart not found');
            }
            const updatedItems = existingCart.items.map((item: any) => {
                if (item.id === itemId) {
                    return { ...item, orderNotes };
                }
                return item;
            });

            const updatedCartData = {
                ...existingCart,
                items: updatedItems,
            };

            const updatedCart = await updateCartById(
                this.authToken,
                updatedCartData
            );
            return updatedCart;
        } catch (error) {
            console.error('Error updating order notes:', error);
            throw error;
        }
    }

    async getAddress() {
        return await getAddressByAuth(this.authToken);
    }

    async getAccount() {
        return await getAccountDetails(this.authToken);
    }

    async getPaymentType() {
        return await paymentType(this.authToken);
    }

    async getCreditUser() {
        return await userCredit(this.authToken);
    }
    async getPaymentStatus(cartId: string) {
        return await paymentStatus(this.authToken, cartId);
    }

    async payForItems(
        name_first: string,
        name_last: string,
        email_address: string,
        m_payment_id: string,
        amount: string,
        item_name: string
    ) {
        try {
            const paymentResult = await pay(
                name_first,
                name_last,
                email_address,
                m_payment_id,
                amount,
                item_name
            );
            return paymentResult;
        } catch (error) {
            console.error('Error during payment:', error);
            throw error;
        }
    }

    async updateUserInformation(
        authToken: string,
        cartId: string,
        billingAddress: any,
        customerOrderNum: string,
        deliveryNotes: string,
        contactNumber: string
    ) {
        const updateData = {
            id: cartId,
            billing_address: billingAddress,
            customer_order_num: customerOrderNum,
            order_notes: deliveryNotes,
            contact_number: contactNumber || '',
        } as any;
        return await updateCartById(authToken, updateData);
    }

    async updatePaymentType(
        authToken: string,
        cartId: string,
        paymentType: any
    ) {
        const updateData = { id: cartId, type: paymentType } as any;
        return await updateCartById(authToken, updateData);
    }
}

export default CartService;
