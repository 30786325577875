import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { apiGetCollections } from "../../../../api/apiCollections";
import PageLayout from "../../layout/PageLayout";
import LoadingScreen from "../../loadingScreen/LoadingScreen";
import TextHeader from "../../textHeader/TextHeader";
import FeaturedGallery from "../../gallery/FeaturedGallery";
import HeroBanner from "../../banner/HeroBanner";

import collectionHeader from "../../../../assets/tempHeaderImages/B.jpg";
import CollectionsGrid from "../../grid/CollectionsGrid";
function ApplicationPage() {
  const { main_category, application_name }: any = useParams();

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationCollections,
  } = useQuery<any, Error>(
    [`application-collections-${application_name}`],
    () => apiGetCollections(main_category, application_name),
    { staleTime: 600000 }
  );


  return (
    <>
      <PageLayout>
        {isLoading && <LoadingScreen />}
        {isError && <div>Something went wrong ...</div>}
        {isSuccess && (
          <>
            <HeroBanner hasTitle={false} imageData={collectionHeader} />
            <TextHeader
              heading={application_name}
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        "
              isCentered={false}
              width="70%"
              marginTop={10}
              marginBottom={5}
            />

            <FeaturedGallery
              images={applicationCollections?.section_featured_collection}
              hasVideo={false}
              invert={false}
              marginTop={5}
              marginBottom={5}
              title={
                applicationCollections?.featured_collection_title
                  ?.collection_name
              }
              description={
                applicationCollections?.featured_collection_description
              }
              itemGroup={
                applicationCollections?.featured_collection_title
                  ?.item_group_code
              }
              application={
                applicationCollections?.featured_collection_title
                  ?.application_name
              }
              collection={
                applicationCollections?.featured_collection_title
                  ?.collection_name
              }
            />

            <CollectionsGrid
              applicationName={application_name}
              collectionData={applicationCollections}
              marginTop={"5rem"}
              marginBottom={"5rem"}
            />
          </>
        )}
      </PageLayout>
    </>
  );
}

export default ApplicationPage;
