import { baseURL } from './baseUrl';

export const apiSearch = async (searchTerm: string) => {
    const response = await baseURL.get(`search?query=${searchTerm}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};


export const resourcesSearch = async (searchTerm: string) => {
    const response = await baseURL.get(`resources/search?query=${searchTerm}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};