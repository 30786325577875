import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../../../components/shared/formFields/InputField';
import SelectField from '../../../components/shared/formFields/SelectField';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import {
  Typography,
  Box,
  Grid,
  Button,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Divider,
} from '@mui/material';

import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface User {
  email?: string;
  given_name?: string;
  family_name?: string;
}

interface ShippingAddressFields {
  email?: string;
  name?: string;
  surname?: string;
  address?: string;
  city?: string;
  country?: string;
  province?: string;
  postalCode?: string;
  suburb?: string;
  apartment?: string;
  contact_number?: number;
}

function UserInformation({
  updateStep,
  formData,
  setIsShipping,
  isShipping,
  setIsDefaultShip,
  isDefaultShip,
}: any) {
  const {
    cartItemList,
    setCartItemList,
    setShippingSelected,
    setDefaultShipSelected,
    cartService,
    cartItemData,
    cartAddressDetails,
    cartId,
  } = useShoppingCart();

  const [isPickUp, setIsPickUp] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({});
  const authToken = cartService.authToken;
  const { user } = useAuth() as {
    user: User | undefined;
  };
  const [deliveryMethod, setDeliveryMethod] = useState(formData.deliveryMethod);

  const formSubmit = handleSubmit(async (data, e) => {
    e?.preventDefault();

    let shippingAddressFields: ShippingAddressFields | undefined;
    const customerOrderNum = data.customerOrderNum;
    const deliveryNotes = data.deliveryNotes;
    const contactNumber = deliveryMethod === 'pickUp' ? '' : data.contactNumber;
    if (deliveryMethod === 'ship') {
      shippingAddressFields = {
        email: user?.email,
        name: data.name,
        surname: data.surname,
        address: cartAddressDetails.physical1,
        apartment: cartAddressDetails.physical2,
        suburb: cartAddressDetails.physical3,
        city: cartAddressDetails.physical4,
        country: cartAddressDetails.physical5,
        province: '',
        postalCode: cartAddressDetails?.physicalPC,
      };
    } else if (deliveryMethod === 'pickUp') {
      shippingAddressFields = {
        name: data.name,
        surname: data.surname,
        email: user?.email,

        address: 'Pick up selected',
      };
    } else if (deliveryMethod === 'delivery') {
      shippingAddressFields = {
        email: user?.email,
        contact_number: data.contact_number,
        name: data.name,
        surname: data.surname,
        address: data.address,
        city: data.city,
        country: data.country,
        province: data.province,
        postalCode: data.postalCode,
        suburb: data.suburb,
        apartment: data.additionalAddressPropertyType,
      };
    }

    try {
      if (cartItemList.length > 0) {
        setCartItemList(
          cartItemList.map((item: any) => {
            return {
              ...item,
              altShippingAddress:
                isShipping || isDefaultShip
                  ? shippingAddressFields
                  : {
                      name: user?.given_name,
                      email: user?.email,
                      address: 'Pick up selected',
                    },
              email: !data.email ? user?.email : data.email,
              orderNotes: data.orderNotes || 'None',
            };
          })
        );
      }
    } catch (error) {
      console.error('Failed to update cart items:', error);
    }

    cartService.updateUserInformation(
      authToken,
      cartId,
      shippingAddressFields,
      customerOrderNum,
      deliveryNotes,
      contactNumber
    );
    updateStep();
  });

  useEffect(() => {
    setDefaultShipSelected(isDefaultShip);

    setShippingSelected(isShipping);
  }, [setShippingSelected, isShipping, isDefaultShip, setDefaultShipSelected]);

  return (
    <>
      <form noValidate onSubmit={formSubmit}>
        <Stack mt={2} mb={1}>
          <Box
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '27px',
              }}
            >
              Customer Order Number
            </Typography>

            <InputField
              defaultValue={cartItemData?.customer_order_num}
              marginTop={1}
              type="customerOrderNum"
              id="customerOrderNum"
              name="customerOrderNum"
              placeholder="Enter customer order number"
              register={register}
              errors={errors}
              errorMsg=""
            />
          </Box>

          <Box
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '27px',
              }}
            >
              Delivery Note
            </Typography>

            <InputField
              defaultValue={cartItemData?.order_notes}
              marginTop={1}
              type="deliveryNotes"
              id="deliveryNotes"
              name="deliveryNotes"
              placeholder="Enter delivery note"
              register={register}
              errors={errors}
              errorMsg=""
              multiline
            />
          </Box>
        </Stack>

        <Stack mt={3} mb={3}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '27px',
              mb: 2,
            }}
          >
            Delivery Method
          </Typography>
          <FormControl id="deliveryMethod">
            <RadioGroup
              aria-labelledby="shipping method"
              defaultValue={formData.deliveryMethod}
              {...register(`deliveryMethod`)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  border: '1px solid #D9D9D9',
                  pl: '17px',
                }}
              >
                <Box sx={{ flex: { xs: 12, lg: 3 } }}>
                  <FormControlLabel
                    value="ship"
                    control={<Radio />}
                    label="Deliver"
                    {...register(`deliveryMethod`)}
                    onClick={() => {
                      setIsDefaultShip(true);
                      setIsShipping(false);
                      setDeliveryMethod('ship');
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  borderRight: '1px solid #D9D9D9',
                  borderLeft: '1px solid #D9D9D9',
                  borderBottom: '1px solid #D9D9D9',

                  pl: '17px',
                }}
              >
                <Box sx={{ flex: { xs: 12, lg: 3 } }}>
                  <FormControlLabel
                    sx={{
                      width: '100%',
                    }}
                    value="pickUp"
                    control={<Radio />}
                    label="Collection"
                    {...register(`deliveryMethod`)}
                    onClick={() => {
                      setIsShipping(false);
                      setIsDefaultShip(false);
                      setIsPickUp(true);
                      setDeliveryMethod('pickUp');
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  borderRight: '1px solid #D9D9D9',
                  borderLeft: '1px solid #D9D9D9',
                  borderBottom: '1px solid #D9D9D9',

                  pl: '17px',
                }}
              >
                <Box sx={{ flex: { xs: 12, lg: 3 } }}>
                  <FormControlLabel
                    value="delivery"
                    control={<Radio />}
                    label="3rd Party Delivery"
                    {...register(`deliveryMethod`)}
                    onClick={() => {
                      setIsShipping(true);
                      setIsDefaultShip(false);
                      setDeliveryMethod('delivery');
                    }}
                  />
                </Box>
              </Box>
            </RadioGroup>
          </FormControl>
        </Stack>

        {isDefaultShip && (
          <Stack>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '27px',
              }}
            >
              Shipping address
            </Typography>

            <InputField
              defaultValue={cartAddressDetails.physical1}
              value={cartAddressDetails.physical1}
              marginTop={2}
              type="text"
              id="address"
              name="address"
              placeholder="Enter address"
              register={register}
              errors={errors}
              errorMsg="Please enter your address"
              disabled
            />

            <InputField
              defaultValue={cartAddressDetails.physical2}
              value={cartAddressDetails.physical2}
              marginTop={2}
              type="text"
              id="additionalAddressPropertyType"
              name="additionalAddressPropertyType"
              placeholder="Enter apartment, suite, etc. (optional)"
              register={register}
              errors={errors}
              disabled
            />

            <InputField
              defaultValue={cartAddressDetails.physical3}
              value={cartAddressDetails.physical3}
              marginTop={2}
              type="text"
              id="suburb"
              name="suburb"
              placeholder="Enter suburb"
              register={register}
              errors={errors}
              errorMsg="Please enter your suburb"
              disabled
            />
            <InputField
              defaultValue={cartAddressDetails.physical4}
              value={cartAddressDetails.physical4}
              marginTop={2}
              type="text"
              id="city"
              name="city"
              placeholder="Enter city"
              register={register}
              errors={errors}
              errorMsg="Please enter your city"
              disabled
            />

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{ flex: 4, mr: 1 }}>
                <InputField
                  defaultValue={cartAddressDetails.physical5}
                  value={cartAddressDetails.physical5}
                  marginTop={2}
                  type="text"
                  id="country"
                  name="country"
                  placeholder="Enter country"
                  // isRequired={true}
                  register={register}
                  errors={errors}
                  errorMsg="Please enter your country"
                  disabled
                />
              </Box>
              <Box sx={{ flex: 4, ml: 0 }}>
                <InputField
                  defaultValue={''}
                  value={''}
                  marginTop={2}
                  type="text"
                  id="province"
                  name="province"
                  placeholder="Enter province"
                  register={register}
                  errors={errors}
                  errorMsg="Please enter your province"
                  disabled
                />
              </Box>
              <Box sx={{ flex: 4, ml: 1 }}>
                <InputField
                  defaultValue={cartAddressDetails.physicalPC}
                  value={cartAddressDetails.physicalPC}
                  marginTop={2}
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  placeholder="Enter postal code"
                  register={register}
                  errors={errors}
                  errorMsg="Please enter your postal code"
                  disabled
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '27px',
                }}
              >
                Recipient Contact Information
              </Typography>

              {/* {isShipping && ( */}
              <InputField
                defaultValue={cartItemData?.contact_number}
                marginTop={2}
                type="number"
                id="contactNumber"
                name="contactNumber"
                placeholder="Enter recipient contact number"
                isRequired={true}
                register={register}
                errors={errors}
                errorMsg="Please enter a valid recipient contact number"
                validationPattern={
                  /^(\+)?(0|27)([1-9])([0-9])( )?([0-9]{3})( )?([0-9]{4})$/
                }
              />

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Box sx={{ flex: 1, mr: 2 }}>
                  {' '}
                  <InputField
                    defaultValue={
                      !cartItemData?.billing_address?.name
                        ? cartItemData?.billing_address?.name
                        : ''
                    }
                    marginTop={2}
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter recipient name"
                    isRequired={true}
                    register={register}
                    errors={errors}
                    errorMsg="Please enter recipient name"
                  />
                </Box>

                <Box sx={{ flex: 1, ml: 2 }}>
                  {' '}
                  <InputField
                    defaultValue={
                      !cartItemData?.billing_address?.name
                        ? cartItemData?.billing_address?.surname
                        : ''
                    }
                    marginTop={2}
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder="Enter recipient surname"
                    isRequired={true}
                    register={register}
                    errors={errors}
                    errorMsg="Please enter recipient surname"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '-10px',
                mt: 2,
              }}
            >
              <Checkbox defaultChecked />
              <Typography
                sx={{
                  color: '#545454',
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                Save this information for next time
              </Typography>
            </Box>
          </Stack>
        )}

        {isShipping && (
          <Stack>
            <Typography>3rd Party Delivery address</Typography>
            <InputField
              defaultValue={formData.address}
              marginTop={2}
              type="text"
              id="address"
              name="address"
              placeholder="Enter address"
              isRequired={true}
              register={register}
              errors={errors}
              errorMsg="Please enter your address"
              maxLength={35}
            />

            <InputField
              defaultValue={formData.additionalAddressPropertyType}
              marginTop={2}
              type="text"
              id="additionalAddressPropertyType"
              name="additionalAddressPropertyType"
              placeholder="Enter apartment, suite, etc. (optional)"
              isRequired={false}
              register={register}
              errors={errors}
              maxLength={35}
            />
            <InputField
              defaultValue={formData.suburb}
              marginTop={2}
              type="text"
              id="suburb"
              name="suburb"
              placeholder="Enter suburb"
              isRequired={false}
              register={register}
              errors={errors}
              maxLength={35}
            />

            <InputField
              defaultValue={formData.city}
              marginTop={2}
              type="text"
              id="city"
              name="city"
              placeholder="Enter city"
              isRequired={true}
              register={register}
              errors={errors}
              errorMsg="Please enter your city"
              maxLength={35}
            />

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box sx={{ flex: 4, mr: 1 }}>
                <SelectField
                  marginTop={2}
                  id="country"
                  register={register}
                  errors={errors}
                  isRequired={true}
                  errorMsg="Please select a country"
                  label="Country/Region"
                  defaultValue={formData.country}
                  itemList={[{ name: 'South Africa' }]}
                />
              </Box>
              <Box sx={{ flex: 4, mr: 1, ml: 1 }}>
                <SelectField
                  marginTop={2}
                  id="province"
                  register={register}
                  errors={errors}
                  isRequired={true}
                  errorMsg="Please select a province"
                  label="Province"
                  defaultValue={formData.province}
                  itemList={[
                    { name: 'Gauteng' },
                    { name: 'Western Cape' },
                    { name: 'Eastern Cape' },
                    { name: 'Limpopo' },
                    { name: 'North West' },
                    { name: 'Kwazulu-Natal' },
                    { name: 'Northern Cape' },
                    { name: 'Mpumalanga' },
                  ]}
                />
              </Box>
              <Box sx={{ flex: 4, ml: 1 }}>
                <InputField
                  defaultValue={formData.postalCode}
                  marginTop={2}
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  placeholder="Enter postal code"
                  isRequired={true}
                  register={register}
                  errors={errors}
                  errorMsg="Please enter your postal code"
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '27px',
                }}
              >
                Recipient Contact Information
              </Typography>

              {/* {isShipping && ( */}
              <InputField
                defaultValue={cartItemData?.contact_number}
                marginTop={2}
                type="number"
                id="contactNumber"
                name="contactNumber"
                placeholder="Enter recipient contact number"
                isRequired={true}
                register={register}
                errors={errors}
                errorMsg="Please enter a valid recipient contact number"
                validationPattern={
                  /^(\+)?(0|27)([1-9])([0-9])( )?([0-9]{3})( )?([0-9]{4})$/
                }
              />

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Box sx={{ flex: 1, mr: 2 }}>
                  {' '}
                  <InputField
                    defaultValue={
                      !cartItemData?.billing_address?.name
                        ? cartItemData?.billing_address?.name
                        : ''
                    }
                    marginTop={2}
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter recipient name"
                    isRequired={true}
                    register={register}
                    errors={errors}
                    errorMsg="Please enter recipient name"
                  />
                </Box>

                <Box sx={{ flex: 1, ml: 2 }}>
                  {' '}
                  <InputField
                    defaultValue={
                      !cartItemData?.billing_address?.name
                        ? cartItemData?.billing_address?.surname
                        : ''
                    }
                    marginTop={2}
                    type="text"
                    id="surname"
                    name="surname"
                    placeholder="Enter recipient surname"
                    isRequired={true}
                    register={register}
                    errors={errors}
                    errorMsg="Please enter recipient surname"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '-10px',
                mt: 2,
              }}
            >
              <Checkbox defaultChecked />
              <Typography>Save this information for next time</Typography>
            </Box>
          </Stack>
        )}

        <Divider sx={{ mt: 2 }} />

        <Grid container mt={2} justifyContent="space-between">
          <Box
            onClick={() => {
              navigate('/');
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '0px',
              cursor: 'pointer',
            }}
          >
            <Typography>Return to cart</Typography>
          </Box>
          <Button
            variant="contained"
            type="submit"
            onClick={() => formSubmit()}
            disableElevation
            sx={{
              borderRadius: '0px',
            }}
            disabled={!isShipping && !isDefaultShip && !isPickUp}
          >
            Next
          </Button>
        </Grid>
      </form>
    </>
  );
}

export default UserInformation;
