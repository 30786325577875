import { apiGetHomePageData } from '../../api/apiHome';
import PageLayout from '../../components/shared/layout/PageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';
import CategoriesOrCollectionsGrid from '../../components/shared/grid/CategoriesOrCollectionsGrid';
import FeaturedGallery from '../../components/shared/gallery/FeaturedGallery';
import Internationals from '../../components/shared/internationals/Internationals';
import NewArrivalsGrid from '../../components/shared/grid/NewArrivalsGrid';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';
import MissingPage from '../../components/shared/errorMessages/MissingPage';
import CarouselBanner from '../../components/shared/banner/CarouselBanner';
import { captureAnalytics } from '../../utilities/Analytics';

function HomePage() {
  // ! custom item group
  const item_group = '999';
  const itemGroupData = {
    category: { item_group: item_group },
  };

  const {
    isError,
    isLoading,
    data: homePageData,
  } = useQuery<any, Error>(
    [`home-page-${item_group}`],
    () => apiGetHomePageData(itemGroupData),
    {
      staleTime: 600000, // Cache data for 10 minutes
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    captureAnalytics('pageview', '/', 'Home');
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <PageLayout>
          {isError && <MissingPage />}
          <CarouselBanner
            images={homePageData?.section_hero}
            video={homePageData?.section_hero_video?.video_url}
          />

          <TextHeader
            heading={'Welcome to the Hertex Trade Portal'}
            content={homePageData.description}
            isCentered={false}
            width="70%"
            marginTop={10}
            marginBottom={5}
          />

          <CategoriesOrCollectionsGrid
            data={homePageData.section_item_group_images}
            hasTitles={false}
            hasButtons={false}
            hasBackgroundText={true}
            spacingValue={6}
            hasCollectionLinks={false}
            marginTop={5}
            marginBottom={5}
          />

          <NewArrivalsGrid
            header="New arrivals"
            data={homePageData?.section_new_arrivals}
            hasTitles={true}
            hasButtons={true}
            hasBackgroundText={false}
            spacingValue={4}
            marginTop={'5rem'}
            marginBottom={'5rem'}
          />
          <FeaturedGallery
            images={homePageData?.section_featured_collection}
            hasVideo={false}
            invert={false}
            marginTop={5}
            marginBottom={5}
            title={homePageData?.featured_collection_title?.collection_name}
            description={homePageData?.featured_collection_description}
            itemGroup={homePageData?.featured_collection_title?.item_group_code}
            application={
              homePageData?.featured_collection_title?.application_name
            }
            collection={
              homePageData?.featured_collection_title?.collection_name
            }
          />

          <Internationals
            marginTop={15}
            marginBottom={15}
            data={homePageData.section_internationals}
            description={homePageData.internationals_description}
          />
        </PageLayout>
      )}
    </>
  );
}

export default HomePage;
