import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    Typography,
    Box,
    Grid,
    Divider,
    Button,
    TextField,
    CircularProgress,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { useShoppingCart } from '../../../context/ShoppingCartContext';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from '../../../context/AuthContext';
import { useEffect, useState } from 'react';
import { Close, Delete } from '@mui/icons-material';

function Cart({ isOpen }: any) {
    const {
        closeCart,
        cartItemList,

        increaseQuantity,
        decreaseQuantity,
        clearCart,
        subTotal,
        vatAmount,
        calculateVatAmount,
        total,
        updateQuantity,
        loadingItemId,
        loadingDeleteItemId,
        removeFromCart,
    } = useShoppingCart();

    useEffect(() => {
        calculateVatAmount();
    }, [calculateVatAmount]);

    const { isAccountOnHold } = useAuth();
    const [errorInput, setErrorInput] = useState(false);
    const handleQuantityChange = (
        elem: any,
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault();
        const value = e.target.value;
        const numberValue = Number(value);
        const isValidInput =
            /^\d+(\.\d{0,1})?$/.test(value) && numberValue <= elem.stock;
        if (isValidInput) {
            updateQuantity(elem.id, numberValue);
            setErrorInput(false);
        } else {
            setErrorInput(true);
        }
    };

    const preventDecimal = (e: React.KeyboardEvent) => {
        const value = (e.target as HTMLInputElement).value;
        const parts = value.split('.');
        if (e.key === '.' && parts.length > 1) {
            e.preventDefault();
        }
        if (
            parts.length === 2 &&
            parts[1].length >= 1 &&
            e.key !== 'Backspace'
        ) {
            e.preventDefault();
        }
    };

    const navigate = useNavigate();
    const list = () => (
        <Box
            sx={{
                width: { xs: '100vw', sm: 550 },
                height: '100%',
                background: '#F5F5F5',
            }}
            role='presentation'
        >
            <Box p={1}>
                {' '}
                <Close
                    onClick={() => {
                        closeCart();
                    }}
                    sx={{ cursor: 'pointer', fontSize: '30px' }}
                />
            </Box>

            {cartItemList.length === 0 ? (
                <>
                    <Grid mt={5} container justifyContent='center'>
                        <Typography fontWeight={'bold'} fontSize={'18px'}>
                            Cart is Empty
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    {cartItemList.length > 0 && (
                        <>
                            <Typography
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    fontSize: '22px',
                                }}
                            >
                                My Cart
                            </Typography>
                        </>
                    )}
                    {loadingDeleteItemId ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {cartItemList?.map((elem: any, index: number) => (
                                <Grid
                                    key={index}
                                    container
                                    sx={{ p: 2 }}
                                    pb={2}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            bgcolor: '#FFF',
                                            borderRadius: '4px',
                                            p: '16px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flex: 1,
                                                height: '92px',
                                                width: '92px',
                                            }}
                                        >
                                            <img
                                                src={elem.image}
                                                alt='cart item'
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                ml: 2,
                                                flex: 4,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                        letterSpacing: '-0.2px',
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    {elem.name}
                                                </Typography>
                                                <IconButton
                                                    onClick={() =>
                                                        removeFromCart(elem)
                                                    }
                                                >
                                                    <Delete
                                                        htmlColor='#000'
                                                        sx={{
                                                            height: '18px',
                                                            width: '18px',
                                                        }}
                                                    />
                                                </IconButton>
                                            </Box>

                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    letterSpacing: '-0.2px',
                                                    lineHeight: '21px',
                                                    color: '#737373',
                                                }}
                                            >{`Description:${' '} ${
                                                elem.description
                                            }`}</Typography>
                                            <Grid
                                                container
                                                justifyContent='space-between'
                                                alignItems='flex-end'
                                            >
                                                {elem.group === 'Fabric' ? (
                                                    <Box>
                                                        <TextField
                                                            defaultValue={
                                                                elem.quantity
                                                            }
                                                            type='text'
                                                            onChange={(e) =>
                                                                handleQuantityChange(
                                                                    elem,
                                                                    e
                                                                )
                                                            }
                                                            variant='outlined'
                                                            size='small'
                                                            error={errorInput}
                                                            onKeyDown={
                                                                preventDecimal
                                                            }
                                                            helperText={
                                                                errorInput ? (
                                                                    <Typography>
                                                                        There
                                                                        are only{' '}
                                                                        {
                                                                            elem.stock
                                                                        }{' '}
                                                                        in
                                                                        stock.
                                                                    </Typography>
                                                                ) : null
                                                            }
                                                            InputProps={{
                                                                sx: {
                                                                    width: '60%',
                                                                    p: '0 0 0 1',
                                                                    background:
                                                                        'linear-gradient(to right, #EAECF0 50%, #FFF 50%)',
                                                                    fontSize:
                                                                        '14px',
                                                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                                                        {
                                                                            '-webkit-appearance':
                                                                                'none',
                                                                            margin: 0,
                                                                        },
                                                                    '& input[type="number"]':
                                                                        {
                                                                            '-moz-appearance':
                                                                                'textfield',
                                                                        },
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <Typography
                                                                            fontSize={
                                                                                '14px'
                                                                            }
                                                                            color={
                                                                                '#000'
                                                                            }
                                                                        >
                                                                            Meters
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            mt: 1,
                                                            display: 'flex',

                                                            alignItems:
                                                                'center',
                                                            border: '#EAECF0 1px solid',
                                                            bgcolor: '#EAECF0',
                                                            ':hover': {
                                                                bgcolor:
                                                                    '#EAECF0',
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            sx={{
                                                                height: '32px',
                                                                width: '32px',
                                                                padding: '0',
                                                                bgcolor: '#FFF',
                                                                ':hover': {
                                                                    bgcolor:
                                                                        '#FFF',
                                                                },
                                                            }}
                                                            disabled={
                                                                elem.quantity ===
                                                                1
                                                                    ? true
                                                                    : false
                                                            }
                                                            onClick={() => {
                                                                decreaseQuantity(
                                                                    elem
                                                                );
                                                            }}
                                                        >
                                                            <RemoveIcon
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            />
                                                        </Button>

                                                        <Typography
                                                            px={3}
                                                            fontSize='14px'
                                                        >
                                                            {elem?.quantity}
                                                        </Typography>
                                                        <Button
                                                            sx={{
                                                                height: '32px',
                                                                width: '32px',

                                                                padding: '0',
                                                                bgcolor: '#FFF',
                                                                ':hover': {
                                                                    bgcolor:
                                                                        '#FFF',
                                                                },
                                                            }}
                                                            disabled={
                                                                elem?.quantity ===
                                                                elem?.stock
                                                            }
                                                            onClick={() => {
                                                                increaseQuantity(
                                                                    elem
                                                                );
                                                            }}
                                                        >
                                                            <AddIcon
                                                                sx={{
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </Box>
                                                )}
                                                {loadingItemId === elem.id ? (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        {`R${elem?.price?.toFixed(
                                                            2
                                                        )}`}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            {elem?.discount_type !==
                                                'normal' && (
                                                <>
                                                    <Typography
                                                        fontWeight={'500'}
                                                    >
                                                        Discounted price
                                                        applied*
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </>
                    )}
                    {cartItemList.length > 0 && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    p: 2,
                                    background: '#F5F5F5',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}
                                >
                                    Subtotal
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                    }}
                                >{`R${subTotal?.toFixed(2)}`}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    p: 2,
                                    background: '#F5F5F5',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#545454',
                                        fontWeight: '400',
                                    }}
                                >
                                    VAT
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: '400',
                                        color: '#545454',
                                    }}
                                >
                                    R{vatAmount?.toFixed(2)}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    p: 2,
                                    background: '#F5F5F5',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#545454',
                                    }}
                                >
                                    Total
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        color: '#333',
                                    }}
                                >
                                    R{total?.toFixed(2)}
                                </Typography>
                            </Box>
                        </>
                    )}
                    {isAccountOnHold && (
                        <Box
                            sx={{
                                display: 'flex',

                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'red',
                                background: '#F5F5F5',
                            }}
                        >
                            <Typography>
                                Your account has been placed on hold.{' '}
                            </Typography>
                            <Typography>
                                Please contact the Hertex team for assistance.{' '}
                            </Typography>
                        </Box>
                    )}

                    <Box
                        pt={3}
                        pb={3}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#F5F5F5',
                        }}
                    >
                        <Button
                            variant='contained'
                            sx={{ width: '80%' }}
                            disableElevation
                            onClick={() => {
                                clearCart();
                            }}
                        >
                            Clear Cart
                        </Button>
                    </Box>
                    <Divider />
                    <Box
                        pt={3}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#F5F5F5',
                        }}
                    >
                        <Button
                            variant='contained'
                            // !If orders go down uncomment below
                            // disabled={true}
                            disabled={isAccountOnHold}
                            sx={{ width: '80%' }}
                            onClick={() => {
                                closeCart();
                                navigate('/checkout');
                            }}
                            disableElevation
                        >
                            Place Order
                        </Button>
                    </Box>

                    <Box
                        pt={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#F5F5F5',
                        }}
                    >
                        <Typography fontWeight='600'>
                            Shipping, taxes and discount codes{' '}
                        </Typography>
                        <Typography fontWeight='600'>
                            calculated at checkout{' '}
                        </Typography>
                        {/* ! If orders go down uncomment below */}
                        {/* <Typography color={'red'}>
                            {' '}
                            Orders have been placed on hold
                        </Typography>
                        <Typography color={'red'}>
                            {' '}
                            we apologise for the inconvenience
                        </Typography> */}
                    </Box>
                </>
            )}
        </Box>
    );

    return (
        <>
            <Drawer anchor={'right'} open={isOpen} onClose={closeCart}>
                {list()}
            </Drawer>
        </>
    );
}

export default Cart;
