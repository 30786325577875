import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TextHeader from '../../components/shared/textHeader/TextHeader';
import CompanyGrid from '../../components/shared/grid/CompanyGrid';
import CarouselBanner from '../../components/shared/banner/CarouselBanner';
import PageLayout from '../../components/shared/layout/PageLayout';
import hertexLogo from '../../assets/logos/hertex-logo-long.png';
import hausLogo from '../../assets/logos/Logo-Haus.png';
import hertex from '../../assets/about-us/hertex.jpg';
import haus from '../../assets/about-us/haus.jpg';
import careers from '../../assets/about-us/careers.jpg';
import { captureAnalytics } from '../../utilities/Analytics';

// Waiting for client feedback

// export const carouselImages = [
//   {
//     url: about_hero,
// },
// {
//   url: hertex,
// },
// {
//   url: haus,
// },
// {
//   url: careers,
// },
// ];

const AboutUsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, 'About Us');
    }, [location.pathname]);

    return (
        <PageLayout>
            <CarouselBanner
                images={''}
                hasVideo={true}
                video={
                    'https://hertex-static-assets.s3.af-south-1.amazonaws.com/videos/Afro+Chic+Landscape+1.mp4'
                }
            />

            <TextHeader
                heading='About us'
                content='Hertex is a leading supplier of high-quality interior fabrics, wallpaper, rugs, furniture and homeware for both the local and international markets. What started in 1987 as a humble Cape Town showroom has since grown to several showrooms countrywide, with more on the way..'
                subContent=''
                isCentered={false}
                width='70%'
                marginTop={10}
                marginBottom={5}
            />

            <CompanyGrid
                marginTop={15}
                marginBottom={15}
                image={hertex}
                hasImage={true}
                tinyLogo={hertexLogo}
                hasButton={true}
                link='/'
                buttonTitle='explore'
                paragraph1='We offer interior and design professionals expertly sourced fabric and wallcoverings that cater to diverse needs, and are suitable for everything from relaxed or luxe residential settings to expert contract and medical applications.'
                paragraph2='In addition to our own collections, Hertex is also the exclusive distributor of several distinguished international brands from the UK, EU and US.'
            />
            <CompanyGrid
                marginTop={15}
                marginBottom={15}
                image={haus}
                isReverse={true}
                hasImage={true}
                tinyLogo={hausLogo}
                hasButtons={false}
                paragraph1='Founded by Hertex, HAUS was created to provide customers with instant access to an expertly curated collection of homeware to complete their interior schemes.'
                paragraph2='We believe a home should be the most inspiring place to be. HAUS features an expansive collection of hand-picked rugs and homeware, including décor accessories, linen, towels, tableware, lighting and furniture from near and far, so you can create a space that feels comfortably you.'
                paragraph3={
                    <span>
                        HAUS collections are available to consumers in Hertex
                        showrooms and online at{' '}
                        <a
                            style={{
                                textDecoration: 'underline',
                                color: 'inherit',
                            }}
                            href={'https://hertexhaus.co.za/'}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            hertexhaus.co.za
                        </a>
                    </span>
                }
            />

            <CompanyGrid
                marginTop={15}
                marginBottom={15}
                image={careers}
                isReverse={false}
                hasImage={false}
                heading='How we work'
                link='../careers'
                buttonTitle='view current vacancies >'
                hasButton={true}
                paragraph1='Hertex is a family-run business that is focused on achieving excellence in everything it does.'
                paragraph2='As a team, we encourage and support one another, and are committed to the success of our customers and the business. At Hertex, every day counts. We communicate with honesty and respect, and work relentlessly, with passion and energy.'
                paragraph3='We are constantly growing, and are on the lookout for talent.'
            />
        </PageLayout>
    );
};

export default AboutUsPage;
