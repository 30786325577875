import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import {
  Typography,
  Box,
  Button,
  Stack,
  Divider,
  Grid,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Colors } from '../../../styles/Theme/ThemeStyles';
import { useQuery } from 'react-query';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import { pickUpPointsData } from '../../../api/pickUpPoints';

type User = {
  given_name: string;
  family_name: string;
  email: string;
};

function Address({ updateStep, previousStep }: any) {
  const {
    cartService,
    shippingSelected,
    defaultShipSelected,
    updateUserInformation,
  } = useShoppingCart();
  const { user } = useAuth() as {
    user: User | undefined;
  };
  const { isLoading, data: cartAddressDetails } = useQuery<any, Error>(
    [`cartAddressDetails`, shippingSelected, defaultShipSelected],
    () => cartService.getCartByUser(),
    {
      enabled: !!cartService.authToken,
    }
  );

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [isDifferentBillingAddress, setIsDifferentBillingAddress] =
    useState<boolean>(false);
  const { handleSubmit } = useForm<any>();
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const formSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
    if (selectedStore) {
      const billingAddress = {
        name: '',
        surname: '',
        email: user?.email || '',
        address: selectedStore?.address,
        apartment: selectedStore?.address,
        suburb: selectedStore?.suburb,
        city: selectedStore?.city,
        postalCode: selectedStore?.postalCode,
        province: selectedStore?.province,
        country: selectedStore?.country,
      };
      updateUserInformation(billingAddress);
    }
    updateStep();
  });

  useEffect(() => {
    if (!shippingSelected || !defaultShipSelected) {
      setIsDifferentBillingAddress(true);
    }
  }, [shippingSelected, defaultShipSelected]);

  return (
    <Box>
      {shippingSelected || defaultShipSelected ? (
        <form noValidate onSubmit={formSubmit}>
          <Stack
            sx={{
              border: `${Colors.info} 1px solid`,
              borderTop: 'none',
              mb: 2,
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  py: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    p: 2,
                  }}
                >
                  <Box sx={{ flex: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '27px',
                      }}
                    >
                      Contact: {cartAddressDetails?.contact_number}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                {(shippingSelected || defaultShipSelected) && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        p: 2,
                      }}
                    >
                      <Box sx={{ flex: 2 }}>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '27px',
                          }}
                        >
                          Ship to:{' '}
                          {cartAddressDetails?.billing_address?.address}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
              </>
            )}
          </Stack>

          <Divider sx={{ mt: 2 }} />
          <Grid container mt={2} justifyContent="space-between">
            <Box
              onClick={previousStep}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '0px',
                cursor: 'pointer',
              }}
            >
              <Typography>Back</Typography>
            </Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => updateStep()}
              disableElevation
              sx={{
                borderRadius: '0px',
              }}
            >
              Next
            </Button>
          </Grid>
        </form>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                py: '10px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                }}
              >
                Select collection
              </Typography>
            </Box>
            <RadioGroup
              onChange={(e) => {
                let selectedStore = null;
                for (const data of pickUpPointsData) {
                  selectedStore = data.stores.find(
                    (store) => store.title === e.target.value
                  );
                  if (selectedStore) break;
                }
                setSelectedStore(selectedStore);
              }}
            >
              {' '}
              {pickUpPointsData.map((data) => (
                <Accordion
                  key={data.title}
                  elevation={0}
                  expanded={expanded === data.title}
                  onChange={handleChange(data.title)}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDown />}
                    aria-controls={`${data.title}-content`}
                    id={`${data.title}-header`}
                  >
                    <Typography
                      sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      {data.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {data.stores.map((store) => (
                        <Box
                          key={store.title}
                          mb={2}
                          sx={{
                            border: '1px solid #F5F5F5',
                            p: '10px',
                          }}
                        >
                          <FormControlLabel
                            value={store.title}
                            control={<Radio />}
                            label={
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                  }}
                                >
                                  {store.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: '400',
                                    fontSize: '12px',
                                  }}
                                >
                                  {`${store.address}, ${store.suburb}, ${store.city}, ${store.province}, ${store.postalCode}, ${store.country}`}
                                </Typography>
                              </>
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </RadioGroup>

            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2} justifyContent="space-between">
              <Box
                onClick={previousStep}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '0px',
                  cursor: 'pointer',
                }}
              >
                <Typography>Back</Typography>
              </Box>
              <Button
                variant="contained"
                type="submit"
                onClick={() => formSubmit()}
                disableElevation
                sx={{
                  borderRadius: '0px',
                }}
              >
                Next
              </Button>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Address;
