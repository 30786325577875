import { Box, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CatalogueList from './CatalogueList';

const ResourcesModalSection = ({
  title,
  resultsData,
  sectionName,
  keyName,
  showSection,
  setShowSection,
  itemCount,
  handleCloseModal,
}: any) => {
  const navigate = useNavigate();

  const handleDownload = (url: any) => {
    window.open(url, '_blank');
  };

  const handleNavigate = (elem: any) => {
    switch (sectionName) {
      case 'Collection':
        navigate(
          `/resources/item_group_name/${elem?.item_group_name}/item_group/${
            elem?.item_group
          }/application/${encodeURIComponent(
            elem.application
          )}/collection/${encodeURIComponent(elem.range)}`
        );
        break;
      case 'Design':
        navigate(
          `/resources/item_group/${
            elem?.item_group
          }/application/${encodeURIComponent(
            elem.application
          )}/collection/${encodeURIComponent(
            elem.range
          )}/design/${encodeURIComponent(elem.design)}`
        );
        break;
      case 'Colour':
        navigate(
          `/resources/item_group/${
            elem?.item_group
          }/application/${encodeURIComponent(
            elem.application
          )}/collection/${encodeURIComponent(
            elem.range
          )}/design/${encodeURIComponent(
            elem.design
          )}/colour/${encodeURIComponent(elem.colour)}`
        );
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            fontWeight={'500'}
            sx={{
              color: '#000',
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            variant="caption"
            sx={{ ml: '0.1rem', color: '#000' }}
          >{`(${itemCount})`}</Typography>

          {showSection ? (
            <KeyboardArrowDown
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setShowSection(!showSection);
              }}
            />
          ) : (
            <KeyboardArrowUp
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setShowSection(!showSection);
              }}
            />
          )}
        </Box>
      </Box>

      {showSection && (
        <Box
          sx={{
            mt: 1,
            maxHeight: '8.5rem',
            overflow: 'auto',
          }}
        >
          {sectionName === 'Catalogue' ? (
            <>
              {resultsData?.results?.Catalogue &&
                resultsData?.results?.Catalogue?.length > 0 && (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      cursor: 'auto',
                      color: '#344054',
                    }}
                  >
                    Click to download catalogue
                  </Typography>
                )}
              <CatalogueList catalogueData={resultsData?.results?.Catalogue} />
            </>
          ) : (
            resultsData?.results?.[sectionName]
              .sort((a: any, b: any) =>
                a?.[keyName]
                  ?.toLowerCase()
                  ?.localeCompare(b?.[keyName]?.toLowerCase())
              )
              ?.map((elem: any, index: number) => (
                <>
                  <Typography
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      color: '#000',
                    }}
                    onClick={() => {
                      handleNavigate(elem);
                      handleCloseModal();
                    }}
                  >
                    {sectionName === 'Collection' &&
                      `${elem?.[keyName]} - ${elem.application}`}
                    {sectionName === 'Colour' &&
                      `${elem?.[keyName]} - ${elem.design} - ${elem.group_desc}`}

                    {sectionName === 'Pricelist' && (
                      <>
                        {index === 0 && (
                          <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                              cursor: 'auto',
                              color: '#344054',
                            }}
                          >
                            Click to download pricelist
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle2"
                          component="div"
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleDownload(elem?.image_data);
                          }}
                        >
                          {elem?.pricelist_name}
                        </Typography>
                      </>
                    )}
                    {sectionName === 'AssemblyInstruction' && (
                      <>
                        {index === 0 && (
                          <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                              cursor: 'auto',
                              color: '#344054',
                            }}
                          >
                            Click to download assembly instruction
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle2"
                          component="div"
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleDownload(elem?.image_data);
                          }}
                        >
                          {elem?.assembly_instruction_name}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </>
              ))
          )}
        </Box>
      )}
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default ResourcesModalSection;
