import PageLayout from "../../components/shared/layout/PageLayout";
import TextHeader from "../../components/shared/textHeader/TextHeader";


import responsibilityImage from "../../assets/responsibiltyCover.png";
import CompanyBanner from "../../components/shared/grid/CompanyBanner";


const ResponsibilityPage = () => {
  return (
    <PageLayout>
      <CompanyBanner
        imageData={responsibilityImage}
        hasTitle={true}
        title="Responsibility"
      />

      <TextHeader
        heading="Our View on Responsibilities"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                "
        isCentered={false}
        width="70%"
        marginTop={10}
        marginBottom={5}
      />

    </PageLayout>
  );
};

export default ResponsibilityPage;
