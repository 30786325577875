import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    Box,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CardsComponent from '../cards/CardsComponents';
import Faqs from '../faqs/Faqs';

const AccordionComponent = ({
    title,
    stores,
    displayFAQs,
    questions,
    isTop,
    panelNumber,
    expanded,
    handleChange,
}: any) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                top: isTop,
            }}
        >
            <Accordion
                elevation={0}
                expanded={expanded === `panel${panelNumber}`}
                onChange={handleChange(`panel${panelNumber}`)}
                sx={{
                    width: { xs: '100%', lg: '80%' },
                    px: { xs: '0', lg: '50px' },
                }}
            >
                <AccordionSummary
                    expandIcon={
                        expanded === `panel${panelNumber}` ? (
                            <RemoveCircleOutlineOutlinedIcon />
                        ) : (
                            <AddCircleOutlineOutlinedIcon />
                        )
                    }
                    aria-controls={`panel${panelNumber}bh-content`}
                    id={`panel${panelNumber}bh-header`}
                    sx={{
                        borderBottom:
                            expanded === `panel${panelNumber}`
                                ? 'none'
                                : '#000 solid 1px',
                    }}
                >
                    <Typography
                        variant='h1'
                        sx={{
                            fontSize: '32px',
                            fontWeight: '500',
                            lineHeight: '28px',
                            color: '#101828',
                            mt: 1,
                        }}
                    >
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        borderBottom: '#000 1px solid',
                    }}
                >
                    <AccordionDetails
                        sx={{ width: { xs: '100%', lg: '100%' } }}
                    >
                        <Grid container spacing={4}>
                            {displayFAQs ? (
                                <Faqs questions={questions} />
                            ) : (
                                stores.map((store: any, index: any) => (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'unset',
                                            justifyContent: 'unset',
                                            m: 0,
                                        }}
                                    >
                                        <CardsComponent
                                            handleOpen={handleOpen}
                                            handleClose={handleClose}
                                            title={store.title}
                                            tradingHours={store.tradingHours}
                                            phone={store.phone}
                                            address={store.address}
                                            mapUrl={store.mapUrl}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </AccordionDetails>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default AccordionComponent;
