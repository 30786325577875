import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import logo from '../../../assets/logos/hertex-logo-light.svg';

import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PieChartIcon from '@mui/icons-material/PieChart';
import { InsertChartOutlined } from '@mui/icons-material';

function Menu() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user') || '');

  const drawerWidth = 240;
  const pages = [
    { text: 'Dashboard' },
    { text: 'Open Sales Orders' },
    { text: 'Closed Sales Orders' },
  ];

  const handleNavigate = (index: number) => {
    let page;
    switch (index) {
      case 0:
        page = '/admin-portal-dashboard';
        break;
      case 1:
        page = '/admin-portal-open-orders';
        break;
      case 2:
        page = '/admin-portal-closed-orders';
        break;
      case 3:
        page = '/admin-portal-product-search';
        break;
    }

    navigate(`${page}`);
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'primary.main',
            overflow: 'hidden',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Grid
          sx={{ width: '80%', cursor: 'pointer' }}
          mt={3}
          ml={3}
          mb={3}
          container
          justifyContent="start"
          onClick={() => navigate('/')}
        >
          <img
            style={{ objectFit: 'contain', width: '80%' }}
            src={logo}
            alt="sportsnation"
          />
        </Grid>

        {pages.map((elem: any, index: number) => (
          <List key={index} style={{ color: '#fff' }}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleNavigate(index);
                }}
              >
                <ListItemIcon style={{ color: '#fff' }}>
                  {index === 0 && <InsertChartOutlined />}
                  {index === 1 && <LayersIcon />}
                  {index === 2 && <LibraryAddCheckIcon />}
                  {index === 3 && <PieChartIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: '12px',
                      }}
                    >
                      {elem.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        ))}

        <Box
          style={{
            flexGrow: '1',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Box style={{ width: '100%' }}>
            <List style={{ color: '#fff' }}>
              {['Support', 'Settings'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      let page: string = '';
                      index === 0
                        ? (page = '/admin-portal-support')
                        : (page = '/admin-portal-settings');
                      navigate(`${page}`);
                    }}
                  >
                    <ListItemIcon style={{ color: '#fff' }}>
                      {index === 0 ? (
                        <SupportOutlinedIcon />
                      ) : (
                        <SettingsOutlinedIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            fontSize: '12px',
                          }}
                        >
                          {text}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider
              variant="middle"
              style={{
                backgroundColor: '#fff',
              }}
            />
            <List sx={{ color: '#fff', display: 'flex' }}>
              <ListItem>
                {/* !User Images unavailable at this time  */}
                {/* <ListItemAvatar>
                                    <Avatar alt='User Image' />
                                </ListItemAvatar> */}
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ListItemText
                    disableTypography
                    style={{
                      margin: '0px',
                      fontSize: '10px',
                    }}
                    primary={
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}
                      >
                        {user.given_name} {user.family_name}
                      </Typography>
                    }
                  />

                  <ListItemText
                    disableTypography
                    sx={{
                      mt: 1,
                      fontSize: '10px',
                    }}
                    primary={
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: '400',
                        }}
                      >
                        {user.email}
                      </Typography>
                    }
                  />
                </Box>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                {' '}
                <ListItemButton
                  onClick={() => {
                    logout();
                  }}
                >
                  <ListItemIcon
                    style={{
                      color: '#fff',
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default Menu;
