import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import { resourcesSearch } from '../../../api/apiSearch';
import { useQuery } from 'react-query';
import { Close } from '@mui/icons-material';
import ResourcesModalSection from './components/ResourcesModalSection';

function ResourcesSearchModal({ modalIsOpen, handleCloseModal }: any) {
    const [searchTerm, setSearchTerm] = useState('');
    const [showCollections, setShowCollections] = useState(false);
    const [showColours, setShowColours] = useState(false);
    const [showCatalogue, setShowCatalogue] = useState(false);
    const [showPricelist, setShowPricelist] = useState(false);
    const [showAssemblyInstruction, setShowAssemblyInstruction] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const {
        data: searchResultsData,
        isLoading,
        isError,
        isSuccess,
    } = useQuery(['search-term', searchTerm], () => resourcesSearch(searchTerm), {
        enabled: !!searchTerm,
    });


    const isEmptyObjectArrays = (
        obj: { [s: string]: unknown } | ArrayLike<unknown>
    ) =>
        Object.values(obj).every(
            (arr) => Array.isArray(arr) && arr.length === 0
        );

    useEffect(() => {
        if (modalIsOpen) {
            setShowCollections(false);
            setShowColours(false);
            setShowCatalogue(false);
            setShowPricelist(false);
            setShowAssemblyInstruction(false);
            setSearchTerm('');
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 0);
        }
    }, [modalIsOpen]);

    return (
        <>
            <Dialog onClose={handleCloseModal} open={modalIsOpen} fullWidth>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    }}
                >
                    <DialogTitle sx={{ flex: 1 }}>
                        {' '}
                        <Typography
                            variant='caption'
                            sx={{
                                color: '#000',
                                fontSize: { xs: '16px', lg: '24px' },
                                fontWeight: '700',
                                overflow: 'hidden',
                            }}
                        >
                            Looking for something?
                        </Typography>
                    </DialogTitle>
                    <Button
                        sx={{
                            px: '0',
                        }}
                        onClick={handleCloseModal}
                    >
                        <Close
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        />{' '}
                    </Button>
                </Box>
                <DialogContent sx={{ width: 'auto', pb: '50px' }}>
                    <DialogContentText sx={{ mb: '0.1rem' }}>
                        <Typography variant='caption'>
                            Type to search
                        </Typography>
                    </DialogContentText>

                    <TextField
                        type='text'
                        id='search'
                        value={searchTerm}
                        inputRef={searchInputRef}
                        autoFocus
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        size='small'
                        fullWidth
                    />
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}

                    {isError && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <Typography>
                                An error occured, please try again later
                            </Typography>
                        </Box>
                    )}
                    {isSuccess && searchTerm.length > 0 && (
                        <>
                            {isEmptyObjectArrays(searchResultsData?.results) ===
                            false ? (
                                <>
                                    <ResourcesModalSection
                                        title={'Collections'}
                                        resultsData={searchResultsData}
                                        sectionName={'Collection'}
                                        keyName={'range'}
                                        showSection={showCollections}
                                        setShowSection={setShowCollections}
                                        itemCount={
                                            searchResultsData?.results
                                                ?.Collection?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ResourcesModalSection
                                        title={'Colours'}
                                        resultsData={searchResultsData}
                                        sectionName={'Colour'}
                                        keyName={'colour'}
                                        showSection={showColours}
                                        setShowSection={setShowColours}
                                        itemCount={
                                            searchResultsData?.results?.Colour
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ResourcesModalSection
                                        title={'Catalogue'}
                                        resultsData={searchResultsData}
                                        sectionName={'Catalogue'}
                                        keyName={'catalogue'}
                                        showSection={showCatalogue}
                                        setShowSection={setShowCatalogue}
                                        itemCount={
                                            searchResultsData?.results?.Catalogue
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ResourcesModalSection
                                        title={'Pricelist'}
                                        resultsData={searchResultsData}
                                        sectionName={'Pricelist'}
                                        keyName={'pricelist'}
                                        showSection={showPricelist}
                                        setShowSection={setShowPricelist}
                                        itemCount={
                                            searchResultsData?.results?.Pricelist
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                    <ResourcesModalSection
                                        title={'Assembly Instruction'}
                                        resultsData={searchResultsData}
                                        sectionName={'AssemblyInstruction'}
                                        keyName={'assembly_instruction'}
                                        showSection={showAssemblyInstruction}
                                        setShowSection={setShowAssemblyInstruction}
                                        itemCount={
                                            searchResultsData?.results?.AssemblyInstruction
                                                ?.length
                                        }
                                        handleCloseModal={handleCloseModal}
                                    />
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <Typography
                                        variant='caption'
                                        sx={{
                                            color: '#000',
                                            fontSize: '22px',
                                            fontWeight: '600',
                                        }}
                                    >
                                        No Items found
                                    </Typography>{' '}
                                </Box>
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ResourcesSearchModal;
