import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';

function SelectField({
    register,
    id,
    itemList,
    defaultValue,
    label,
    isRequired,
    errors,
    errorMsg,
    marginTop,
    disabled,
}: any) {
    return (
        <Box sx={{ minWidth: 120, mt: marginTop }}>
            <FormControl size='small' fullWidth>
                <InputLabel id={id}>{label}</InputLabel>
                <Select
                    id={id}
                    labelId={id}
                    defaultValue={defaultValue}
                    label={label}
                    {...register(`${id}`, { required: isRequired })}
                    error={errors[id] ? true : false}
                    disabled={disabled}

                >
                    {itemList.map((elem: any, index: number) => (
                        <MenuItem key={index} value={elem.name}>
                            {elem.name}
                        </MenuItem>
                    ))}
                </Select>
                <Typography
                    sx={{
                        color: '#d32f2f',
                        fontSize: '0.75rem',
                        m: '0 14px',
                        mt: '4px',
                        display: errors[id] ? 'block' : 'none',
                    }}
                >
                    {errors[id] ? errorMsg : ''}
                </Typography>
            </FormControl>
        </Box>
    );
}

export default SelectField;
