import TextHeader from '../../components/shared/textHeader/TextHeader';
import { Container } from '@mui/material';
import ResourcesCatalogueGrid from './components/ResourcesCatalogueGrid';
import { apiFetchCatalogues } from '../../api/apiResources';
import { useQuery } from 'react-query';
import ResourcesPageLayout from '../../components/shared/layout/ResourcesPageLayout';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';
import MissingPage from '../../components/shared/errorMessages/MissingPage';

function ResourcesCatalogues() {
    const {
        isSuccess,
        isError,
        isLoading,
        data: catalogueData,
    } = useQuery<any, Error>(['catalogue'], () => apiFetchCatalogues());
    const catalogueItems = catalogueData
        ? catalogueData?.map((image: any) => {
              return {
                  img: image.thumbnail_image_data,
                  url: image.resource_url,
              };
          })
        : [];
    return (
        <ResourcesPageLayout>
            {isLoading && <LoadingScreen />}
            {isError && <MissingPage />}
            {isSuccess && (
                <Container maxWidth='xl'>
                    <TextHeader
                        heading={'Catalogues'}
                        isCentered={false}
                        width='100%'
                        marginTop={10}
                        marginBottom={0}
                    />
                    {catalogueItems?.length !== 0 && (
                        <ResourcesCatalogueGrid
                            header=''
                            data={catalogueItems}
                        />
                    )}
                </Container>
            )}
        </ResourcesPageLayout>
    );
}

export default ResourcesCatalogues;
