import { Box } from '@mui/material';
import AdminPortalMenu from './AdminPortalMenu';
import AdminPortalPageSpacer from './AdminPortalPageSpacer';
import AdminPortalMobileMenu from './AdminPortalMobileMenu';

function AdminPortalPageLayout({ children }: any) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <AdminPortalMenu />
      </Box>

      <AdminPortalPageSpacer>
        <Box
          sx={{
            display: { xs: 'block', lg: 'none' },
          }}
        >
          <AdminPortalMobileMenu />
        </Box>

        {children}
      </AdminPortalPageSpacer>
    </Box>
  );
}

export default AdminPortalPageLayout;
