import { Grid, Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ResourcesProductImg from '../../../assets/resources/Collection Squares 1200 x 1200.jpg';
import ResourcesPriceListImg from '../../../assets/resources/Brown_texture.jpg';

import ResourcesGeneralImg from '../../../assets/faq-page/careershero.jpg';
import ResourcesCatalogueImg from '../../../assets/resources/Kyoto Garden6.jpg';


function ResourcesMenuGrid() {
  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Grid
        container
        rowSpacing={'16px'}
        columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
        sx={{ mb: 10 }}
      >
        {/* PRODUCTS */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              height: { xs: '320px', lg: '320px' },
              minHeight: '100%',
              overflow: 'hidden',
              '&:hover img': {
                transform: 'scale(1.03)',
              },
            }}
            onClick={() => {
              navigate('/resources/products');
            }}
          >
            <img
              src={ResourcesProductImg}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.5s ease',
              }}
              alt="categories"
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'end',
                padding: '0 0 20px 30px',
                gap: '20px',
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '35px',
                  textTransform: 'capitalize',
                  lineHeight: '0',
                }}
              >
                Product Images
              </Typography>
              <Typography
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                Download available Hertex and HAUS images
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* CATALOGUES */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              height: { xs: '320px', lg: '320px' },
              minHeight: '100%',
              overflow: 'hidden',
              '&:hover img': {
                transform: 'scale(1.03)',
              },
            }}
            onClick={() => {
              navigate('/resources/catalogues');
            }}
          >
            <img
              src={ResourcesCatalogueImg}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.5s ease',
              }}
              alt="categories"
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'end',
                padding: '0 0 20px 30px',
                gap: '20px',
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '35px',
                  textTransform: 'capitalize',
                  lineHeight: '0',
                }}
              >
                Catalogues
              </Typography>
              <Typography
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                View and download available catalogues
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* PRICE LISTS */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              height: { xs: '320px', lg: '320px' },
              minHeight: '100%',
              overflow: 'hidden',
              '&:hover img': {
                transform: 'scale(1.03)',
              },
            }}
            onClick={() => {
              navigate('/resources/pricelists');
            }}
          >
            <img
              // src={ResourcesPricelistImg} //revert this line of code after Black Friday
              src={ResourcesPriceListImg} //remove this line of code when Black Friday ends
              style={{
                  width: '100%',
                  height: 'auto', // Allow height to scale based on the width
                  objectFit: 'cover', // or 'contain' as needed
                  background: '#244c9c', // optional, for black friday styling
                  objectPosition: 'center',
                  transition: 'transform 0.5s ease',
              }}

              alt="categories"
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'end',
                padding: '0 20px 20px 30px',
                gap: '20px',
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '35px',
                  textTransform: 'capitalize',
                  lineHeight: '0',
                }}
              >
                Price Lists
              </Typography>
              <Typography
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                Download PDF price lists for Hertex, Hertex Internationals and
                HAUS
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* GENERAL INFO */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: 'relative',
              cursor: 'pointer',
              width: '100%',
              height: { xs: '320px', lg: '320px' },
              minHeight: '100%',
              overflow: 'hidden',
              '&:hover img': {
                transform: 'scale(1.03)',
              },
            }}
            onClick={() => {
              navigate('/resources/general-info');
            }}
          >
            <img
              src={ResourcesGeneralImg}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.5s ease',
              }}
              alt="categories"
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'end',
                padding: '0 0 20px 30px',
                gap: '20px',
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '35px',
                  textTransform: 'capitalize',
                  lineHeight: '0',
                }}
              >
                General Information
              </Typography>
              <Typography
                sx={{
                  textAlign: 'start',
                  fontWeight: 'lighter',
                  color: '#fff',
                  fontSize: '16px',
                }}
              >
                Access answers to frequently asked questions here
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ResourcesMenuGrid;
