import { Box, Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CompanyGrid({
  image,
  marginTop,
  marginBottom,
  isReverse,
  heading,
  headingTwo,
  padTop,
  isContained,
  buttonTitle,
  tinyLogo,
  hasImage,
  hasButton,
  hasButtons,
  buttonTitleTwo,
  link,
  paragraph1,
  paragraph2,
  paragraph3,
  hasExternalLink,
  externalLink,
  linkTwo,
}: any) {
  const navigate = useNavigate();
  return (
    <Grid
      mt={{ xs: 0, lg: marginTop }}
      mb={{ xs: 0, lg: marginBottom }}
      container
      sx={{
        width: '100%',
        height: { xs: 'auto', lg: '70vh' },

        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: isReverse ? 'row-reverse' : 'row',
        },
      }}
    >
      <Box
        pt={{ xs: '0', lg: padTop }}
        sx={{
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          pt: '40px',
        }}
      >
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: isContained ? 'contain' : 'cover',
          }}
          src={image}
          alt="trade"
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: hasButtons === false ? 'start' : 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            p: { xs: '0px 1rem', lg: '0' },
            width: { xs: '100%', lg: '50%' },
          }}
        >
          {hasImage ? (
            <Box
              sx={{
                width:
                  hasButtons === false
                    ? { xs: '33%', md: '25%', lg: '33%' }
                    : { xs: '50%', md: '25%', lg: '50%' },
              }}
            >
              {' '}
              <img
                src={tinyLogo}
                alt="/"
                style={{
                  paddingTop: '50px',
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
          ) : (
            <Typography
              mt={2}
              variant="h3"
              sx={{
                pt: '20px',
                fontSize: '56px',
                fontWeight: '500',
                lineHeight: '72px',
              }}
            >
              {heading}
            </Typography>
          )}
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography mt={4} variant="h3">
              {headingTwo}
            </Typography>
            <Typography
              mt={2}
              fontFamily={'Inter'}
              sx={
                hasButtons === false
                  ? {
                      fontWeight: '300',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      lineHeight: '27.5px',
                    }
                  : {
                      fontWeight: '400',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      whiteSpace: 'pre-wrap',
                      lineHeight: '27.5px',
                    }
              }
            >
              {paragraph1}
            </Typography>
            <Typography
              mt={2}
              fontFamily={'Inter'}
              sx={
                hasButtons === false
                  ? {
                      fontWeight: '300',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      lineHeight: '27.5px',
                    }
                  : {
                      fontWeight: '400',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      whiteSpace: 'pre-wrap',
                      lineHeight: '27.5px',
                    }
              }
            >
              {paragraph2}
            </Typography>
            <Typography
              mt={2}
              fontFamily={'Inter'}
              sx={
                hasButtons === false
                  ? {
                      fontWeight: '300',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      lineHeight: '27.5px',
                    }
                  : {
                      fontWeight: '400',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      whiteSpace: 'pre-wrap',
                      lineHeight: '27.5px',
                    }
              }
            >
              {paragraph3}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'start',
              width: '100%',
            }}
          >
            {hasButton ? (
              <Button
                sx={{
                  mt: '3rem',
                  width: {
                    xs: '100%',
                    md: '400px',
                    lg: '400px',
                  },
                  borderRadius: 0,
                  p: { xs: '0.5rem 2rem', sm: '1rem 2.5rem' },
                }}
                variant="outlined"
                onClick={() => {
                  if (hasExternalLink) {
                    window.open(externalLink, '_blank');
                  } else {
                    navigate(link);
                  }
                }}
              >
                {buttonTitle}
              </Button>
            ) : (
              hasButtons && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Button
                    sx={{
                      mt: '3rem',
                      width: { xs: '100%', lg: '300px' },
                      borderRadius: 0,
                      p: {
                        xs: '0.5rem 2rem',
                        lg: '1rem 2.5rem',
                      },
                    }}
                    variant="outlined"
                    onClick={() => navigate(link)}
                  >
                    {buttonTitle}
                  </Button>

                  <Button
                    sx={{
                      mt: '3rem',
                      width: { xs: '100%', lg: '300px' },
                      borderRadius: 0,
                      p: {
                        xs: '0.5rem 2rem',
                        sm: '1rem 2.5rem',
                      },
                    }}
                    variant="outlined"
                    onClick={() => window.open(linkTwo, '_blank')}
                  >
                    {buttonTitleTwo}
                  </Button>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default CompanyGrid;
