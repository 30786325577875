import '../src/styles/App.css';
import AppProvider from './provider/provider';
import MaintenanceMode from './components/shared/maintenanceMode/MaintenanceMode';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-5XS679NGG4');

function App() {
  const maintenanceMode = false;
  // // !!Disable right click on entire site
  document.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });
  return <>{maintenanceMode ? <MaintenanceMode /> : <AppProvider />}</>;
}

export default App;
