import React, { useEffect, useState } from 'react';
import CartService from '../../service/CartService';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/shared/loadingScreen/LoadingScreen';
import { getCartByUserId } from '../../api/apiCart';

const PayfastStatus = () => {
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authToken') || '';
    const customerId = localStorage.getItem('customerId') || '';
    const cartService = new CartService(authToken, customerId);
    const [isLoading, setIsLoading] = useState(false);

    const { data: cartItemData } = useQuery(
        'cart',
        () => getCartByUserId(authToken, { status_check: 'PROCESSING' }),
        {
            enabled: !!authToken,
        }
    );

    // !Original
    const payFastMutation = useMutation(
        () => cartService.finalizeUserCart(cartItemData?.id),

        {
            onMutate: () => {
                setIsLoading(true);
            },
            onSuccess: (data) => {
                const checkStatus = async (
                    attempts: number = 40,
                    interval: number = 500
                ) => {
                    try {
                        const status = await cartService.getPaymentStatus(
                            cartItemData?.id
                        );
                        if (status.status === 'FINALIZED') {
                            navigate('/success');
                        } else if (attempts > 0) {
                            setTimeout(
                                () => checkStatus(attempts - 1, interval),
                                interval
                            );
                        } else {
                            throw new Error('Payment status check timed out');
                        }
                    } catch (error) {
                        console.error('Error fetching payment status:', error);
                        navigate('/order-failed');
                    } finally {
                        if (attempts <= 1) {
                            setIsLoading(false);
                        }
                    }
                };

                checkStatus();
            },
            onError: (error) => {
                console.error('Mutation onError:', error);
                navigate('/order-failed');
                setIsLoading(false);
            },
        }
    );

    useEffect(() => {
        if (cartItemData?.id && authToken && !isLoading) {
            setIsLoading(true);
            payFastMutation.mutate();
        } else {
        }
    }, [cartItemData?.id, authToken, payFastMutation, isLoading]);

    return <>{isLoading && <LoadingScreen />}</>;
};

export default PayfastStatus;
