import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActionArea,
} from '@mui/material';

interface CatalogueItem {
    application: string;
    catalogue_name: string;
    item_group: string;
    item_group_name: string;
    range: string;
    resource_url: string;
    thumbnail_file_name: string;
    thumbnail_image_data: string;
}

interface CatalogueListProps {
    catalogueData: CatalogueItem[];
}

const CatalogueList: React.FC<CatalogueListProps> = ({ catalogueData }) => {
    const handleDownload = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <Box sx={{ paddingY: 2, paddingRight: 2 }}>
            <Grid container spacing={2}>
                {catalogueData.map((catalogue, index) => (
                    <Grid item xs={12} key={index}>
                        <Card
                            elevation={1}
                            sx={{
                                borderRadius: '0',
                            }}
                        >
                            <CardActionArea
                                onClick={() =>
                                    handleDownload(catalogue.resource_url)
                                }
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CardMedia
                                        component='img'
                                        sx={{ width: 60, height: 60 }}
                                        image={catalogue.thumbnail_image_data}
                                        alt={catalogue.thumbnail_file_name}
                                    />
                                    <CardContent sx={{ flex: 1 }}>
                                        <Typography
                                            variant='body2'
                                            component='div'
                                        >
                                            {catalogue.catalogue_name}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CatalogueList;
