import { Box, Grid, CircularProgress } from '@mui/material';
import logo from '../../../assets/logos/Hertex_Icon.jpg';

function LoadingScreen() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems={'center'}
      sx={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: '100px',
            height: '100px',
            marginBottom: '2rem',
          }}
        />
        <CircularProgress />
      </Box>
    </Grid>
  );
}

export default LoadingScreen;
