import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import Cart from '../components/shared/shoppingCart/Cart';
import CartService from '../service/CartService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from './AuthContext';
import { discount } from '../api/apiCart';

interface DiscountData {
  discount_type: string;
  discount_total: number;
}

type ShoppingCartContextType = {
  setFormSteps: any;
  formSteps: number;
  setShippingSelected: any;
  shippingSelected: any;
  setDefaultShipSelected: any;
  defaultShipSelected: any;
  openCart: () => void;
  closeCart: () => void;
  setCartItemList: any;
  addToCart: (item: object) => void;
  removeFromCart: (item: object) => void;
  increaseQuantity: (item: object) => void;
  decreaseQuantity: (item: object) => void;
  calculateVatAmount: () => void;
  addShippingPrice: (price: number) => void;
  addAdditionalCartItemDetails: (
    cartItem: object,
    alternativeShippingAddress: object
  ) => void;
  updateOrderNotes: (itemId: string, orderNotes: string) => void;
  clearCart: () => void;
  cartItemList: Array<object>;
  itemTotal: number;
  total: number;
  vatAmount: number;
  shippingPrice: number;
  cartService: CartService;
  calculateItemTotal: () => number;
  setStockLink: (stock_link: string) => void;
  updateQuantity: (cartId: string, newQuantity: number) => void;
  discountAmount: any;
  discountAmount_total: number;
  discountAmount_type: string;
  loadingItemId: string | null;
  loadingDeleteItemId: string | null;
  billingAddress: any;
  cartId: string;
  cartItemData: any;
  cartAddressDetails: any;
  updateUserInformation: (
    billingAddress: any,
    customerOrderNum?: any,
    deliveryNotes?: any,
    contactNumber?: any
  ) => void;
  subTotal: number;
};

type ShoppingCartProviderProps = {
  children: ReactNode;
};

const ShoppingCartContext = createContext({} as ShoppingCartContextType);

export const ShoppingCartProvider = ({
  children,
}: ShoppingCartProviderProps) => {
  const [formSteps, setFormSteps] = useState<any>();

  const [loadingItemId, setLoadingItemId] = useState<string | null>(null);
  const [loadingDeleteItemId, setLoadingDeleteItemId] = useState<string | null>(
    null
  );
  const [billingAddress, setBillingAddress] = useState<any>(null);
  const [cartId, setCartId] = useState<string>('');

  const [cartItemList, setCartItemList] = useState<any>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [itemTotal, setItemTotal] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);

  const [vatAmount, setVatAmount] = useState<number>(0);

  const [shippingPrice, setShippingPrice] = useState<number>(0);

  const [shippingSelected, setShippingSelected] = useState<boolean>();

  const [defaultShipSelected, setDefaultShipSelected] = useState<boolean>();
  const [stockLink, setStockLink] = useState<string>('');
  const { isLogin, setIsAccountOnHold } = useAuth();

  const authToken = localStorage.getItem('authToken') || '';
  const customerId = localStorage.getItem('customerId') || '';
  const cartService = useMemo(
    () => new CartService(authToken, customerId),
    [authToken, customerId]
  );
  const vat = 0.15;
  const queryClient = useQueryClient();

  const { data: cartAddressDetails } = useQuery(
    'cartAddress',
    () => cartService.getAddress(),
    {
      initialData: [],
      enabled: !!authToken && isLogin,
    }
  );
  const { data: cartItemData } = useQuery(
    ['cart'],
    () => cartService.checkAndRetrieveCart(),
    {
      initialData: [],
      enabled: !!authToken && !!isLogin,
      onSuccess: (data) => {
        if (data?.on_hold === 'true') {
          setIsAccountOnHold(true);
        }
        setCartId(data?.id);
        setBillingAddress(data?.billing_address || {});
      },
    }
  );

  const { data: discountAmount } = useQuery(
    ['discount', stockLink],
    () => discount(authToken, Number(stockLink)),
    {
      enabled: !!authToken && !!stockLink,
    }
  );
  const [discountAmountTotal, setDiscountAmountTotal] = useState<number>(0);
  const [discountAmountType, setDiscountAmountType] = useState<string>('');
  useEffect(() => {
    if (discountAmount) {
      setDiscountAmountTotal(discountAmount?.discount_total || 0);
      setDiscountAmountType(discountAmount?.discount_type || '');
    }
  }, [discountAmount]);

  const openCart = () => {
    setIsOpen(true);
  };

  const closeCart = () => {
    setIsOpen(false);
  };

  const addToCartMutation = useMutation(
    (updatedCartItemsList: any[]) => {
      const currentCartId = cartItemData.id;
      return cartService.updateCartItems(currentCartId, updatedCartItemsList);
    },
    {
      onSuccess: (data, variables) => {
        setCartItemList(variables);
        queryClient.invalidateQueries('cart');
        setIsOpen(true);
      },
      onError: (error) => {
        console.error('Error adding items', error);
      },
    }
  );

  const addToCart = async (cartItem: any) => {
    const existingCartItemIndex = cartItemList.findIndex(
      (item: any) => item.id === cartItem.id
    );

    let updatedCartItemsList: any[];

    const fetchDiscountData = async (item: any) => {
      if (item.stock_link) {
        try {
          const discountData = await discount(
            authToken,
            Number(item.stock_link)
          );
          return discountData?.discount_total;
        } catch (error) {
          console.error('Error fetching discount data', error);
          return item.price * vat + item.price;
        }
      }
      return item.price * vat + item.price;
    };

    if (existingCartItemIndex !== -1) {
      updatedCartItemsList = await Promise.all(
        cartItemList.map(async (item: any, index: number) => {
          if (index === existingCartItemIndex) {
            const updatedQuantity = Math.min(
              item.quantity + cartItem.quantity,
              item.stock
            );
            const newPrice = await fetchDiscountData(item);
            return {
              ...item,
              price: (newPrice * vat + newPrice) * updatedQuantity,
              quantity: updatedQuantity,
            };
          }
          return item;
        })
      );
    } else {
      const newPrice = await fetchDiscountData(cartItem);
      updatedCartItemsList = [
        ...cartItemList,
        {
          ...cartItem,
          price: (newPrice * vat + newPrice) * cartItem.quantity,
        },
      ];
    }

    addToCartMutation.mutate(updatedCartItemsList);
  };
  const removeFromCart = (cartItem: any) => {
    setLoadingDeleteItemId(cartItem.id);
    const updatedCartItemsList = cartItemList.filter(
      (item: any) => item.id !== cartItem.id
    );
    const currentCartId = cartItemData.id;
    cartService
      .removeItemFromCart(currentCartId, cartItem.id)
      .then(() => {
        setCartItemList(updatedCartItemsList);
        queryClient.invalidateQueries('cart');
        setLoadingDeleteItemId(null);
      })
      .catch((error) => {
        console.error('Error removing item: ', error);
        setLoadingDeleteItemId(null);
      });
  };

  const clearCartMutation = useMutation(
    () => {
      const currentCartId = cartItemData.id;
      return cartService.clearCartItems(currentCartId);
    },
    {
      onSuccess: () => {
        setCartItemList([]);
      },
      onError: (error) => {
        console.error('Error clearing cart:', error);
      },
    }
  );

  const clearCart = () => {
    clearCartMutation.mutate();
  };

  const updateCartMutation = useMutation(
    (updatedCartItemsList: any[]) => {
      const currentCartId = cartItemData.id;
      return cartService.updateCartItems(currentCartId, updatedCartItemsList);
    },
    {
      onSuccess: (data, variables) => {
        setCartItemList(variables);
        queryClient.invalidateQueries('cart');
        setLoadingItemId(null);
      },
      onError: (error) => {
        console.error('Error adding items', error);
      },
    }
  );

  const updateQuantity = async (cartItemId: any, newQuantity: number) => {
    setLoadingItemId(cartItemId);

    const updatedCartItemsList = await Promise.all(
      cartItemList.map(async (item: any) => {
        if (item.id === cartItemId) {
          let discountData: DiscountData | null = null;
          if (item.stock_link) {
            try {
              discountData = await discount(authToken, Number(item.stock_link));
            } catch (error) {
              console.error('Error fetching discount data', error);
            }
          }

          const newPrice =
            (discountData?.discount_total ?? item.price) * newQuantity;
          return {
            ...item,
            quantity: newQuantity,
            price: newPrice * vat + newPrice,
          };
        }
        return item;
      })
    );

    updateCartMutation.mutate(updatedCartItemsList);
  };

  const increaseQuantityMutation = useMutation(
    (updatedCartItemsList: any[]) => {
      const currentCartId = cartItemData.id;
      return cartService.updateCartItems(currentCartId, updatedCartItemsList);
    },
    {
      onSuccess: (data, variables) => {
        setCartItemList(variables);
        queryClient.invalidateQueries('cart');
        setLoadingItemId(null);
      },
      onError: (error: any) => {
        console.error('Error increasing items:', error);
      },
    }
  );

  const increaseQuantity = async (cartItem: any) => {
    setLoadingItemId(cartItem.id);
    const existingCartItem = cartItemList.find(
      (item: any) => item.id === cartItem.id
    );
    let discountData: DiscountData | null = null;
    if (cartItem.stock_link) {
      try {
        discountData = await discount(authToken, Number(cartItem.stock_link));
      } catch (error) {
        console.error('Error fetching discount data', error);
      }
    }
    if (existingCartItem) {
      const updatedCartItemsList = cartItemList.map((item: any) => {
        if (item.id === cartItem.id) {
          const newQuantity = item.quantity + 1;

          const newPrice =
            (discountData?.discount_total ?? item.price) * newQuantity;
          return {
            ...item,
            quantity: newQuantity,
            price: newPrice * vat + newPrice,
          };
        }
        return item;
      });
      increaseQuantityMutation.mutate(updatedCartItemsList);
    }
  };

  const decreaseQuantityMutation = useMutation(
    (updatedCartItemsList: any[]) => {
      const currentCartId = cartItemData.id;
      return cartService.updateCartItems(currentCartId, updatedCartItemsList);
    },
    {
      onSuccess: (data, variables) => {
        setCartItemList(variables);
        queryClient.invalidateQueries('cart');
        setLoadingItemId(null);
      },
      onError: (error: any) => {
        console.error('Error decreasing items:', error);
      },
    }
  );

  const decreaseQuantity = async (cartItem: any) => {
    setLoadingItemId(cartItem.id);
    const existingCartItem = cartItemList.find(
      (item: any) => item.id === cartItem.id
    );

    let discountData: DiscountData | null = null;
    if (cartItem.stock_link) {
      try {
        discountData = await discount(authToken, Number(cartItem.stock_link));
      } catch (error) {
        console.error('Error fetching discount data', error);
      }
    }

    if (existingCartItem && existingCartItem.quantity > 1) {
      const updatedCartItemsList = cartItemList.map((item: any) => {
        if (item.id === cartItem.id) {
          const newQuantity = item.quantity - 1;
          const newPrice =
            (discountData?.discount_total ?? item.price) * newQuantity;
          return {
            ...item,
            quantity: newQuantity,
            price: newPrice * vat + newPrice,
          };
        }
        return item;
      });
      decreaseQuantityMutation.mutate(updatedCartItemsList);
    } else if (existingCartItem && existingCartItem.quantity === 1) {
      removeFromCart(cartItem);
    }
  };

  const calculateItemTotal = useCallback(() => {
    const sumOfItems = cartItemList.reduce((acc: number, item: any) => {
      return acc + item.price;
    }, 0);

    setItemTotal(sumOfItems);
    return sumOfItems;
  }, [cartItemList]);

  const calculateVatAmount = useCallback(() => {
    const vatRate = 0.15;
    const vatAmount = itemTotal - itemTotal / (1 + vatRate);
    setVatAmount(vatAmount);
  }, [itemTotal]);

  const totalAmount = useCallback(() => {
    const overallTotal = itemTotal;
    setTotal(overallTotal);
  }, [itemTotal]);

  const calculateSubtotal = useCallback(() => {
    const subtotal = itemTotal - vatAmount;
    setSubTotal(subtotal);
  }, [itemTotal, vatAmount]);

  const addShippingPrice = (price: number) => {
    setShippingPrice(price);
  };

  const addAdditionalCartItemDetails = (
    cartItem: any,
    alternativeShippingAddress: object
  ) => {
    const updatedCartItemsList = cartItemList.map((item: any) => {
      if (item.id === cartItem.id) {
        return {
          ...item,
          altShippingAddress: alternativeShippingAddress,
        };
      }
      return item;
    });

    const currentCartId = cartItemData.id;
    cartService
      .updateCartItems(currentCartId, updatedCartItemsList)
      .then(() => {
        setCartItemList(updatedCartItemsList);
      })
      .catch((error) => {
        console.error('Error updating cart item details:', error);
      });
  };

  const updateOrderNotes = async (itemId: string, orderNotes: string) => {
    try {
      await cartService.updateOrderNotes(cartItemData?.id, itemId, orderNotes);
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error updating order notes:', error);
    }
  };

  const updateUserInformation = async (
    billingAddress: any,
    customerOrderNum?: any,
    deliveryNotes?: any,
    contactNumber?: any
  ) => {
    try {
      await cartService.updateUserInformation(
        authToken,
        cartId,
        billingAddress,
        customerOrderNum,
        deliveryNotes,
        contactNumber
      );
      setBillingAddress(billingAddress);
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error updating user information:', error);
    }
  };

  useEffect(() => {
    if (
      cartItemData &&
      Array.isArray(cartItemData.items) &&
      cartItemData.items.length > 0
    ) {
      setCartItemList(cartItemData.items);
    }
  }, [cartItemData]);

  useEffect(() => {
    calculateItemTotal();
    totalAmount();
    calculateSubtotal();
  }, [
    calculateItemTotal,
    cartService,
    total,
    itemTotal,
    totalAmount,
    calculateSubtotal,
  ]);

  return (
    <ShoppingCartContext.Provider
      value={{
        setFormSteps,
        setShippingSelected,
        setCartItemList,
        openCart,
        closeCart,
        addToCart,
        removeFromCart,
        increaseQuantity,
        decreaseQuantity,
        calculateVatAmount,
        addShippingPrice,
        addAdditionalCartItemDetails,
        updateOrderNotes,
        clearCart,
        formSteps,
        shippingSelected,
        cartItemList,
        itemTotal,
        total,
        vatAmount,
        shippingPrice,
        cartService,
        calculateItemTotal,
        setDefaultShipSelected,
        defaultShipSelected,
        updateQuantity,
        setStockLink,
        discountAmount,
        discountAmount_total: discountAmountTotal,
        discountAmount_type: discountAmountType,
        loadingItemId,
        loadingDeleteItemId,
        billingAddress,
        cartItemData,
        cartAddressDetails,
        cartId,
        updateUserInformation,
        subTotal,
      }}
    >
      {children}
      <Cart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};
