import ResourcesPageLayout from '../../components/shared/layout/ResourcesPageLayout';
import TextHeader from '../../components/shared/textHeader/TextHeader';
import ResourcesMenuGrid from '../../components/shared/grid/ResourcesMenuGrid';
import { useEffect } from 'react';
import { Container } from '@mui/material';

function ResourcesHome() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ResourcesPageLayout>
            <Container maxWidth='xl'>
                <TextHeader
                    heading={'Trade Resources'}
                    content={
                        'Welcome to Trade Resources: Download pricelists, catalogues, product images, and sales support materials here.'
                    }
                    isCentered={false}
                    width='100%'
                    marginTop={10}
                    marginBottom={0}
                />
                <ResourcesMenuGrid />
            </Container>
        </ResourcesPageLayout>
    );
}

export default ResourcesHome;
